@import '../../../../styles/common';

.inputBorders {
  border-color: $newLightGray;
  border-width: 1.5px;
}

.selectBorders {
  border: 1.5px solid $newLightGray;
  border-radius: 8px;
}

.maxInputWidth {
  width: 600px;
}

.narrowInput {
  width: 60px;
  min-width: 60px;
}

.rowContainer {
  max-width: 950px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.titleText {
  font-size: 14px;
  font-weight: 600;
  color: $darkGray;
}

.marginRight {
  margin-right: 8px;
}

.addClientRowContainer {
  margin-top: 32px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: solid 2px $primary-border-color;

  .newClientTitle {
    font-size: 15px;
    font-weight: 600;
    color: $blue;
  }
}

.addedClientsContainer {
  max-width: 500px;

  .clientItemContainer {
    padding-bottom: 8px;
  }
  .addedClientTitle {
    font-size: 14px;
    white-space: pre-line;
  }

  .removeClientLink {
    font-size: 14px;
    font-weight: 600;
    color: $red;

    cursor: pointer;
  }

  .removeClientLink:active {
    color: $selectRed;
  }
}
