@import '../../../../styles/common';

.inputBorders {
  border-color: $newLightGray;
  border-width: 1.5px;
}

.maxInputWidth {
  width: 600px;
}

.narrowInput {
  width: 60px;
  min-width: 60px;
}

.rowContainer {
  max-width: 950px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.spaceBetweenRow {
  width: 650px !important;
  justify-content: space-between;
}

.titleText {
  font-size: 14px;
  font-weight: 600;
  color: $darkGray;
}

.marginRight {
  margin-right: 8px;
}

.marginLeft {
  margin-left: 8px;
}

.marginTop {
  margin-top: 16px;
}

.error {
  color: $red;
}

.errorInputContainer {
  border-color: $red;
}

.errorInputContainer:focus {
  border-color: $red;
}
