@import '../../../styles/vars';
@import '../../../styles/colors';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dateInput {
  div {
    border-radius: 5px;
    border-color: $newLightGray;
    border-width: 1.5px;
  }
  resize: none;
  font-size: $largeSizeText;
  min-width: 150px;
}

.separator {
  margin-right: 8px;
  margin-left: 8px;

  font-size: 14px;
  font-weight: 600;
  color: $darkGray;
}
