@import '../../../../styles/colors';
@import '../../../../styles/common';

.participantsTable {
  box-shadow: none;
  border: none;
}

.link {
  font-weight: 600;
  text-decoration: none !important;
  color: $blue !important;
}

.successLink {
  color: $inputGreen !important;
}
