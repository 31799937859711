@import '../../../../styles/colors';
@import '../../../../styles/vars';
@import '../../../../styles/common';

.participantsContainer {
  display: flex;
  height: 100%;
}

.participantsContent {
  width: 100%;

  .participantsTable {
    box-shadow: none;
    border: none;
    max-height: calc(100% - $buttonHeight - $primaryPadding);
  }

  .link {
    font-weight: 600;
    text-decoration: none !important;
    color: $red !important;
  }
}

.addPrticipantContainer {
  width: 100%;

  padding-top: $primaryPadding;
  padding-bottom: $primaryPadding;

  position: absolute;
  bottom: 0;

  background-color: white;

  border-bottom: solid 2px $primary-border-color;
  border-top: solid 2px $primary-border-color;

  .title {
    font-size: 16px;
    font-weight: 600;
    color: $blue;
  }

  .marginLeft {
    margin-left: 32px;
  }

  .infoInput {
    width: 250px;
    border-color: $newLightGray;
    border-width: 1.5px;
  }

  .errorInputContainer {
    border-color: $red;
    &:focus {
      outline: none;
      border-color: $red;
    }
  }

  .errorText {
    position: absolute;
    bottom: 5px;
    margin-top: 7px;
    font-size: 11px;
    color: $red;
  }
}
