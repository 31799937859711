@import '../../../styles/common';
@import '../../../styles/colors';
@import '../../../styles/vars';

.hidden {
  display: none !important;
}

.fullHeight {
  height: 100%;
}

.createGameDialogBody {
  height: 80%;
  position: relative;
}

.dialogTitle.dialogTitle {
  color: $blue;
  font-weight: 600;
  font-size: 22px;
  line-height: 25px;
}

.selectTitle {
  font-size: 14px;
  font-weight: 600;
  color: $darkGray;
  margin-right: 32px;
}

.addConnectionLink {
  font-size: 14px;
  font-weight: 600;
  color: $blue;

  cursor: pointer;
}

.addConnectionLink:active {
  color: $color-light-blue;
}

.availabilityDialog {
  height: 350px;
}

.selectAllConnectionContainer {
  margin-bottom: 24px;

  .selectAllCheckbox {
    margin-right: 16px;
  }
}

.connectionsContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 24px;

  .removeConnectionLink {
    font-size: 14px;
    font-weight: 600;
    color: $red;

    cursor: pointer;
  }

  .removeConnectionLink:active {
    color: $selectRed;
  }

  .connectionItemContainer {
    padding-bottom: 8px;
  }

  .connectionTitle {
    font-size: 14px;
    white-space: pre-line;
  }
}

.inputBorders {
  border-color: $newLightGray;
  border-width: 1.5px;
}
.errorValidationText {
  margin-top: 8px;
  font-size: 12px;
  color: $red;
}
.maxInputWidth {
  width: 500px;
}

.titleText {
  font-size: 14px;
  font-weight: 600;
  color: $darkGray;
}

.marginRight {
  margin-right: 24px;
}
