@import '../../../../styles/common';

.inputBorders {
  border-color: $newLightGray;
  border-width: 1.5px;
}

.maxInputWidth {
  width: 600px;
}

.narrowInput {
  width: 60px;
  min-width: 60px;
}

.rowContainer {
  max-width: 780px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.titleText {
  font-size: 14px;
  font-weight: 600;
  color: $darkGray;
}
.marginRight {
  margin-right: 8px;
}

.addFormRowContainer {
  margin-top: 32px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: solid 2px $primary-border-color;

  .newFormTitle {
    font-size: 15px;
    font-weight: 600;
    color: $blue;
  }
}

.addedFormContainer {
  max-width: 500px;

  .formItemContainer {
    padding-bottom: 8px;
  }
  .addedFormTitle {
    font-size: 14px;
    white-space: pre-line;
  }

  .removeFormLink {
    font-size: 14px;
    font-weight: 600;
    color: $red;

    cursor: pointer;
  }

  .removeFormLink:active {
    color: $selectRed;
  }
}
